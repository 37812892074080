import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-light",
	"padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "56px 34px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "36px 0",
			"md-grid-gap": "50px 0",
			"margin": "0px 130px 0px 130px",
			"lg-margin": "0px 0 0px 0",
			"md-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"align-items": "flex-start",
			"md-padding": "0px 0 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"md-margin": "0px 0px 20px 0px",
			"children": "Підніміть свій бренд за допомогою креативних рішень Qovilex"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sansHelvetica",
			"color": "#474a4d",
			"md-margin": "0px 0px 25px 0px",
			"children": "Зробіть наступний крок у розвитку свого бренду - станьте партнером Qovilex вже сьогодні!"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://up.qovilex.com/img/2.jpg",
			"display": "block",
			"width": "100%",
			"md-order": "-1"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;